'use client';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

import { PAGE_PATTERNS } from '@/constants/pagePatterns';
import { trackerManager } from '@/lib/tracker/tracker-manager';

function getPageName(pathname: string): string {
  const matchedPage = PAGE_PATTERNS.find(({ pattern }) => pattern.test(pathname));

  if (matchedPage) {
    return matchedPage.name;
  }

  const firstSegment = pathname.split('/')?.[1];
  return firstSegment ?? 'unknown';
}

function PageViewEventProvider() {
  const pathname = usePathname();

  useEffect(() => {
    const pageName = getPageName(pathname);

    trackerManager.emit('pageView', {
      page: pageName,
    });
    
  }, [pathname]);

  return null;
}

export { PageViewEventProvider };
