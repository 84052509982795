'use client';

import { config } from '@/config/config';
import { ENV } from '@/config/env';
import { useEffect } from 'react';

function PwaProvider() {
  useEffect(() => {
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator && !config.isLocal) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register(`${ENV.BASE_PATH}/sw.js`).then(
          (registration) => {
            console.info('Service Worker registered with scope: ', registration.scope);
          },
          (err) => {
            console.info('Service Worker registration failed: ', err);
          }
        );
      });
    }
  }, []);

  return null;
}

export { PwaProvider };
