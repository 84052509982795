import { STORAGE } from '@/constants';
import { type TSendEventData, sendEvent } from '@/services/general/sendEvent';
import { getStorage } from '@/utils';
import { generateUUID } from '@/utils/client-utils';
import { getPlatform } from '@/utils/getPlatform';

type TEventOnData = {
  page: string;
};
export function pageView(data: TEventOnData) {
  const appName = 'celebrationsB2B';
  const brand = 'B2B';
  const storage = getStorage('local');

  let browserUUID = storage.getItem(STORAGE.browserUUID) ?? '';
  if (!browserUUID) {
    browserUUID = generateUUID();
    storage.setItem(STORAGE.browserUUID, browserUUID);
  }

  const platform = getPlatform();
  const userAgent = window.navigator.userAgent;
  const { innerWidth: width, innerHeight: height } = window; // Screen width and height

  const eventData: TSendEventData = {
    eventType: 'clickstream.pageview',
    eventSubType: 'browse',
    deviceTimeCreated: new Date().toISOString(),
    page: {
      url: window.location.href,
      height,
      width,
      type: data.page,
    },
    device: {
      appName,
      userAgent,
      height,
      width,
      platform: platform.os,
      deviceType: platform.browser,
    },
    browserUUID,
  };

  
  sendEvent({
    params: { brand, entryBrand: brand },
    data: eventData,
  });
}
