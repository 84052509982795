'use client';

import { config } from '@/config/config';
import { EventEmitter } from '../event-emitter';
import { clickstream } from './events/clickstream';
import { gtm } from './events/gtm';

const events = ['pageView'] as const;
type TEvents = (typeof events)[number];
type TTrackers = 'clickstream' | 'gtm';

export const trackerManager = new EventEmitter<TEvents>();

type TTracker = {
  events: TEvents[];
  enabled: boolean;
  client: Record<TEvents, (data: unknown) => void>;
};
const trackers: Record<TTrackers, TTracker> = {
  clickstream: {
    events: ['pageView'],
    enabled: config.tracker.clickstream.enabled,
    client: clickstream as Record<TEvents, (data: unknown) => void>,
  },
  gtm: {
    events: [],
    enabled: config.tracker.gtm.enabled,
    client: gtm as Record<TEvents, (data: unknown) => void>,
  },
};

for (const event of events) {
  trackerManager.on(event as TEvents, (data: unknown) => {
    for (const tracker in trackers) {
      const activeTracker = trackers[tracker as TTrackers];

      if (activeTracker.enabled) {
        const eventAction = activeTracker.client[event as TEvents];
        if (eventAction) eventAction(data);
      }
    }
  });
}
