'use client';

type EventCallback<T = unknown> = (data: T) => void;

export class EventEmitter<TEvents> {
  events: Record<string, EventCallback<unknown>[]> = {};

  on<T>(event: TEvents, callback: EventCallback<T>) {
    if (!this.events[event as string]) {
      this.events[event as string] = [];
    }
    this.events[event as string].push(callback as EventCallback<unknown>);
  }

  emit<T>(event: TEvents, data: T) {
    const eventCallbacks = this.events[event as string];
    if (eventCallbacks) {
      for (const callback of eventCallbacks) {
        callback(data);
      }
    }
  }

  off<T>(event: TEvents, callback: EventCallback<T>) {
    if (!this.events[event as string]) return;
    this.events[event as string] = this.events[event as string].filter((cb) => cb !== callback);
  }
}
