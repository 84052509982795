import { ENV } from '@/config/env';
import { BASE_URL } from '@/constants/baseUrl';
import { getStorage } from '@/utils';
import dayjs from 'dayjs';
import qs from 'qs';

export type TSendEventParams = {
  brand: string;
  entryBrand: string;
};

export type TSendEventData = {
  eventType: string;
  eventSubType: string;
  deviceTimeCreated: string;
  page: {
    url: string;
    height: number;
    width: number;
    type: string;
  };
  device: {
    appName: string;
    userAgent: string;
    height: number;
    width: number;
    platform: string;
    deviceType: string;
  };
  user?: {
    customerType: string;
    gaClientId: string;
  };
  browserUUID: string;
};

type TSendEventArgs = { params: TSendEventParams; data: TSendEventData };

export const sendEvent = async ({ params, data }: TSendEventArgs) => {
  const baseUrl = ENV.NEXT_PUBLIC_CLICKSTREAM_API_BASE_URL;

  let clickStreamGuessToken = getStorage('local').getItem('click-stream-guess-token');
  if (!clickStreamGuessToken) {
    const response = await fetch(`${BASE_URL.localService}/api/clickstream/guess-token`);
    const responseData = await response.json();
    const tokenData = responseData?.data;

    if (!tokenData) {
      console.error('Error getting click-stream guess token:', responseData);
      return;
    }

    const TOKEN_EXPIRE_BUFFER = 60 * 60; // 60 seconds * 60 minutes = 3600 seconds = 1 hour
    clickStreamGuessToken = tokenData.access_token as string;
    const expirationDate = dayjs()
      .add(tokenData.expires_in - TOKEN_EXPIRE_BUFFER, 'second')
      .toDate();

    getStorage('local').setItem('click-stream-guess-token', clickStreamGuessToken, {
      expires: expirationDate,
    });
  }

  try {
    // Using qs to stringify query parameters
    const queryString = qs.stringify(params);

    const response = await fetch(`${baseUrl}?${queryString}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        anonymousid: data.browserUUID,
        'app-version': ENV.BUILD_VERSION as string,
        authorization: `Bearer ${clickStreamGuessToken}`,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseData = await response.json();
    console.info('Event successfully sent:', responseData);
  } catch (error) {
    console.error('Error sending event:', error);
  }
};
