// types.ts
interface Platform {
  os: string;
  browser: string;
  version: string;
}

interface BrowserInfo {
  engine?: string;
  name: string;
  version: string;
}

interface UserAgentBrand {
  brand: string;
  version: string;
}

interface UserAgentData {
  brands?: UserAgentBrand[];
  platform?: string;
}

interface NavigatorExtended extends Navigator {
  deviceMemory?: number;
  connection?: {
    effectiveType?: string;
    downlink?: number;
    rtt?: number;
    saveData?: boolean;
  };
  userAgentData?: UserAgentData;
}

// platform.ts
const removeExcessMozillaAndVersion = /^mozilla\/\d\.\d\W/;
const brandList = Object.freeze(['chrome', 'opera', 'safari', 'edge', 'firefox']);
const unknown = 'Unknown';
const empty = '';

const navigator: NavigatorExtended | null = typeof window !== 'undefined' ? window.navigator : null;

let userAgentData: UserAgentData | null = navigator?.userAgentData || null;
let userAgent: string | null = navigator?.userAgent ?? null;

const mobiles: Record<string, RegExp> = {
  iphone: /iphone/,
  ipad: /ipad|macintosh/,
  android: /android/,
};

const desktops: Record<string, RegExp> = {
  windows: /win/,
  mac: /macintosh/,
  linux: /linux/,
};

interface BrowserPattern {
  pattern: RegExp;
  name: string;
  versionPattern?: RegExp; // Bazı browserlar için özel versiyon pattern'i
}

const BROWSER_PATTERNS: BrowserPattern[] = [
  // Edge (Chromium)
  {
    pattern: /edg\/([0-9.]+)/i,
    name: 'Edge',
  },
  // Legacy Edge
  {
    pattern: /edge\/([0-9.]+)/i,
    name: 'Edge Legacy',
  },
  // Internet Explorer
  {
    pattern: /trident/i,
    name: 'Internet Explorer',
    versionPattern: /rv:([0-9.]+)/i,
  },
  {
    pattern: /msie ([0-9.]+)/i,
    name: 'Internet Explorer',
  },
  // Yandex Browser
  {
    pattern: /yabrowser\/([0-9.]+)/i,
    name: 'Yandex',
  },
  // UC Browser
  {
    pattern: /ucbrowser\/([0-9.]+)/i,
    name: 'UC Browser',
  },
  // Opera
  {
    pattern: /opr\/([0-9.]+)/i,
    name: 'Opera',
  },
  // Opera Mini
  {
    pattern: /opera mini\/([0-9.]+)/i,
    name: 'Opera Mini',
  },
  // Samsung Internet
  {
    pattern: /samsungbrowser\/([0-9.]+)/i,
    name: 'Samsung Browser',
  },
  // Brave - Note: Brave is mostly identical to Chrome
  {
    pattern: /brave\/([0-9.]+)/i,
    name: 'Brave',
  },
  // Vivaldi
  {
    pattern: /vivaldi\/([0-9.]+)/i,
    name: 'Vivaldi',
  },
  // Chrome
  {
    pattern: /chrome\/([0-9.]+)/i,
    name: 'Chrome',
  },
  // Firefox
  {
    pattern: /firefox\/([0-9.]+)/i,
    name: 'Firefox',
  },
  // Firefox for iOS
  {
    pattern: /fxios\/([0-9.]+)/i,
    name: 'Firefox',
  },
  // Chrome for iOS
  {
    pattern: /crios\/([0-9.]+)/i,
    name: 'Chrome',
  },
  // Safari
  {
    pattern: /version\/([0-9.]+)\s+safari/i,
    name: 'Safari',
  },
];

function getOperatingSystem(ua: string): string {
  const mobileOS = Object.keys(mobiles).find(
    (os) => mobiles[os].test(ua) && (navigator?.maxTouchPoints ?? 0) >= 1
  );
  const desktopOS = Object.keys(desktops).find((os) => desktops[os].test(ua));
  return mobileOS ?? desktopOS ?? unknown;
}

function getBrowserEngine(ua: string): string {
  const lowerUA = ua.toLowerCase();

  if (lowerUA.includes('gecko/')) return 'Gecko';
  if (lowerUA.includes('trident/')) return 'Trident';
  if (lowerUA.includes('presto/')) return 'Presto';
  if (lowerUA.includes('webkit/')) return 'WebKit';
  if (lowerUA.includes('blink/')) return 'Blink';

  return 'Unknown';
}

function matchBrowser(ua: string): BrowserInfo {
  const lowerUA = ua.toLowerCase();
  const engine = getBrowserEngine(ua);

  for (const browser of BROWSER_PATTERNS) {
    const match = browser.pattern.exec(lowerUA);

    if (match) {
      // If there is a custom version pattern, use that
      if (browser.versionPattern) {
        const versionMatch = browser.versionPattern.exec(lowerUA);
        return {
          engine,
          name: browser.name,
          version: versionMatch ? versionMatch[1] : '',
        };
      }

      return {
        engine,
        name: browser.name,
        version: match[1] || '',
      };
    }
  }

  // Safari fallback kontrolü
  if (lowerUA.includes('safari') && !lowerUA.includes('chrome') && !lowerUA.includes('android')) {
    const webkitMatch = /webkit\/([0-9.]+)/i.exec(lowerUA);
    return {
      engine,
      name: 'Safari',
      version: webkitMatch ? webkitMatch[1] : '',
    };
  }

  return {
    engine,
    name: 'Unknown',
    version: '',
  };
}

export function debugUserAgent(ua: string): void {
  const lowerUA = ua.toLowerCase();
  console.info('Debug UA:', {
    original: ua,
    lowercase: lowerUA,
    engine: getBrowserEngine(ua),
    browserChecks: {
      hasChrome: lowerUA.includes('chrome'),
      hasSafari: lowerUA.includes('safari'),
      hasFirefox: lowerUA.includes('firefox'),
      hasEdge: lowerUA.includes('edg'),
      hasIE: lowerUA.includes('trident') || lowerUA.includes('msie'),
      hasOpera: lowerUA.includes('opr'),
      hasYandex: lowerUA.includes('yabrowser'),
      hasUC: lowerUA.includes('ucbrowser'),
      hasSamsung: lowerUA.includes('samsungbrowser'),
      hasBrave: lowerUA.includes('brave'),
      hasVivaldi: lowerUA.includes('vivaldi'),
    },
    matches: BROWSER_PATTERNS.reduce(
      (acc, browser) => ({
        // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
        ...acc,
        [browser.name]: browser.pattern.exec(lowerUA),
      }),
      {}
    ),
  });
}

function detectMobileBrowser(ua: string): string | null {
  const isMobile = /Mobile|Android|iPhone|iPad|iPod/i.test(ua);
  if (!isMobile) return null;

  if (/iPhone|iPad|iPod/i.test(ua)) return 'iOS';
  if (/Android/i.test(ua)) return 'Android';
  return 'Mobile';
}

function getBrowserInfo(ua: string): BrowserInfo {
  if (!ua) return { name: 'Unknown', version: '' };

  const mobilePlatform = detectMobileBrowser(ua);
  if (mobilePlatform) {
    return matchBrowser(ua);
  }

  return matchBrowser(ua);
}

function getPlatformFromUserAgentData(data: UserAgentData): Platform {
  const os = data.platform?.toLowerCase() ?? unknown;
  let platformData: Omit<Platform, 'os'> = { browser: unknown, version: unknown };

  for (const agentBrand of data.brands || []) {
    const agentBrandEntry = agentBrand.brand.toLowerCase();
    const foundBrand = brandList.find((brand) => agentBrandEntry.includes(brand));
    if (foundBrand) {
      platformData = { browser: foundBrand, version: agentBrand.version };
      break;
    }
  }

  return { os, ...platformData };
}

export function getPlatform(
  customUserAgent?: string,
  customUserAgentData?: UserAgentData
): Platform {
  userAgent = typeof customUserAgent === 'string' ? customUserAgent : userAgent;
  userAgentData = customUserAgentData || userAgentData;

  if (userAgent) {
    const ua = userAgent.toLowerCase().replace(removeExcessMozillaAndVersion, empty);
    const os = getOperatingSystem(ua);
    const { name, version } = getBrowserInfo(ua);
    return { os, browser: name, version };
  }

  if (userAgentData) {
    return getPlatformFromUserAgentData(userAgentData);
  }

  return {
    os: unknown,
    browser: unknown,
    version: unknown,
  };
}
