export const PAGE_PATTERNS = [
  {
    pattern: /^\/$/,
    name: 'home',
  },
  {
    pattern: /^\/business$/,
    name: 'business',
  },
  {
    pattern: /^\/enterprise$/,
    name: 'enterprise',
  },
  //! Some other pattern examples
  // {
  //   pattern: /^\/product\/[\w-]+\/detail$/,
  //   name: 'product-detail'
  // },
  // {
  //   pattern: /^\/product\/[\w-]+\/edit$/,
  //   name: 'product-edit'
  // },
  // {
  //   pattern: /^\/category\/[\w-]+$/,
  //   name: 'category'
  // },
  // {
  //   pattern: /^\/blog\/[\w-]+$/,
  //   name: 'blog-post'
  // }
] as const;
