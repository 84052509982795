'use client';

import { useTranslations } from 'next-intl';
import Image from 'next/image';
import Link from 'next/link';

import { footerListData } from '@/data/footerListData';
import { LINKS } from '@/utils/getLink';
import { Button } from '../atoms';
import { Divider } from '../atoms/Divider';
import { Icon } from '../atoms/Icon';

function renderCustomerServiceTextP(chunks: React.ReactNode) {
  return <p>{chunks}</p>;
}
function renderCustomerServiceTextLink(chunks: React.ReactNode) {
  return (
    <a href={LINKS.customerService} target="_blank" rel="noreferrer" className="underline">
      {chunks}
    </a>
  );
}

export function Footer() {
  const t = useTranslations();

  const customerServiceTextLink = t.rich('customerServiceTextLink', {
    p: renderCustomerServiceTextP,
    link: renderCustomerServiceTextLink,
  });

  return (
    <footer className="w-full bg-navy-500 text-fg-inverse-default">
      <div className="container flex flex-col gap-8 pt-3 pb-6 sm:gap-16 sm:py-10">
        <div className="flex items-end gap-1">
          <Image
            src="/brand-icon.svg"
            alt="Brand icon"
            width={32}
            height={40}
            className="h-8 w-auto lg:h-10"
          />
          <Image
            src="/brand-text-light.svg"
            alt="Brand text"
            width={200}
            height={32}
            className="h-6 object-contain object-left lg:h-8"
          />
        </div>

        <div className="grid-col-1 grid sm:grid-cols-2 sm:gap-10 lg:grid-cols-9">
          <div className="relative order-1 col-span-1 mb-4 lg:col-span-2">
            <input type="checkbox" id="contact-information" hidden className="peer" />
            <label
              className="flex items-center justify-between peer-checked:mb-4 sm:mb-4"
              htmlFor="contact-information"
            >
              <h2 className="subtitle2-bold">{t('contactInformation')}</h2>
            </label>
            <Icon
              name="right-chevron"
              className="absolute top-0 right-0 transition-transform peer-checked:rotate-90 sm:hidden"
              size="sm"
            />

            <div className="mb-8 hidden flex-col gap-4 text-balance peer-checked:flex sm:flex">
              <div>
                <h3 className="mb-2">Corporate office</h3>
                <div>
                  <p className="body2-regular text-fg-weakest">2 Jericho Plaza</p>
                  <p className="body2-regular text-fg-weakest">Jericho, NY 11753</p>
                </div>
              </div>
              <div>
                <h3 className="mb-2">Business gift service</h3>
                <div>
                  <p className="body2-regular text-fg-weakest">Email: corporate@gmail.com</p>
                  <p className="body2-regular text-fg-weakest">Phone: 1-888-755-7474</p>
                </div>
              </div>
            </div>
            <div className="hidden sm:block">
              <div id="irmlink" />
            </div>
          </div>

          <div className="order-3 col-span-1 mb-6 flex grid-cols-1 flex-col flex-wrap gap-4 sm:col-span-2 sm:mb-0 sm:grid-cols-2 sm:flex-row sm:gap-10 lg:order-2 lg:col-span-4">
            {footerListData.map((data) => (
              <div
                className="relative min-w-full max-w-full sm:min-w-[calc(50%-20px)] sm:max-w-[calc(50%-20px)]"
                key={data.title}
              >
                <input type="checkbox" id={data.title} hidden className="peer" />
                <label
                  className="flex items-center justify-between peer-checked:mb-4 sm:mb-4"
                  htmlFor={data.title}
                >
                  <h2 className="subtitle2-bold">{data.title}</h2>
                </label>
                <Icon
                  name="right-chevron"
                  className="absolute top-0 right-0 transition-transform peer-checked:rotate-90 sm:hidden"
                  size="sm"
                />
                <ul className="peer-checked:fade-in hidden flex-col gap-2 peer-checked:flex sm:flex">
                  {data.links.map((link) => (
                    <li className="body1-regular animate-link-center" key={link.text}>
                      <Link
                        href={link.url}
                        {...(!link.isLocal ? { target: '_blank' } : {})}
                        prefetch={false}
                      >
                        {link.text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          <div className="order-3 col-span-1 sm:order-2 lg:order-3 lg:col-span-3">
            <h2 className="subtitle2-bold mb-4">{customerServiceTextLink}</h2>

            <div className="mb-6 flex flex-col gap-2 rounded bg-navy-400 p-2 sm:mb-10 sm:gap-4 sm:p-4">
              <div className="flex items-center gap-1">
                <Icon name="phone" size="md" />
                <p className="subtitle2-bold">{t('talkToAnExpert')}</p>
              </div>

              <div>
                <p className="body1-regular">
                  {t('callUs')} ({t('callUsTimeline')})
                </p>
                <Button
                  variant="text"
                  color="white"
                  iconPosition="left"
                  as="a"
                  href="tel:1-888-755-7474"
                  className="col-span-1 px-1 lg:col-span-2"
                  size="sm"
                >
                  <p className="body1-regular text-fg-inverse-default">
                    <b>{t('callUsNumber')}</b>
                  </p>
                </Button>
              </div>
            </div>

            <div>
              <h2 className="subtitle2-bold mb-4">{t('followUs')}</h2>
              <div className="flex items-center gap-4">
                <a
                  href={LINKS.linkedin}
                  target="_blank"
                  rel="noreferrer"
                  className="duration-150 hover:text-yellow-500"
                  aria-label={t('flowersProfileOnSocial', { socialMedia: 'Linkedin' })}
                >
                  <Icon name="linkedin" />
                </a>
                <a
                  href={LINKS.facebook}
                  target="_blank"
                  rel="noreferrer"
                  className="duration-150 hover:text-yellow-500"
                  aria-label={t('flowersProfileOnSocial', { socialMedia: 'Facebook' })}
                >
                  <Icon name="facebook" />
                </a>
                <a
                  href={LINKS.instagram}
                  target="_blank"
                  rel="noreferrer"
                  className="duration-150 hover:text-yellow-500"
                  aria-label={t('flowersProfileOnSocial', { socialMedia: 'Instagram' })}
                >
                  <Icon name="instagram" />
                </a>
                <a
                  href={LINKS.pinterest}
                  target="_blank"
                  rel="noreferrer"
                  className="duration-150 hover:text-yellow-500"
                  aria-label={t('flowersProfileOnSocial', { socialMedia: 'Pinterest' })}
                >
                  <Icon name="pinterest" />
                </a>
                <a
                  href={LINKS.youtube}
                  target="_blank"
                  rel="noreferrer"
                  className="duration-150 hover:text-yellow-500"
                  aria-label={t('flowersProfileOnSocial', { socialMedia: 'Youtube' })}
                >
                  <Icon name="youtube" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <Divider className="w-full" color="strongest" />

        <div className="flex flex-col items-center justify-center gap-6 lg:flex-row">
          <p className="caption1-regular">
            {t('copyrightText', { year: new Date().getFullYear() })}
          </p>
          <ul className="flex flex-wrap items-center justify-center gap-2">
            <li>
              <a
                href={LINKS.termsOfUse}
                target="_blank"
                rel="noreferrer"
                className="caption1-regular border-b text-neutral-25 duration-150 hover:border-yellow-500 hover:text-yellow-500"
              >
                {t('termsOfUse')}
              </a>
            </li>
            <Divider as="li" className="h-4" color="strongest" orientation="vertical" />
            <li>
              <a
                href={LINKS.privacyNotice}
                target="_blank"
                rel="noreferrer"
                className="caption1-regular border-b text-neutral-25 duration-150 hover:border-yellow-500 hover:text-yellow-500"
              >
                {t('privacyNotice')}
              </a>
            </li>
            <Divider as="li" className="h-4" color="strongest" orientation="vertical" />
            <li>
              <a
                href={LINKS.accessibilityStatement}
                target="_blank"
                rel="noreferrer"
                className="caption1-regular border-b text-neutral-25 duration-150 hover:border-yellow-500 hover:text-yellow-500"
              >
                {t('accessibilityStatement')}
              </a>
            </li>
            <Divider as="li" className="h-4" color="strongest" orientation="vertical" />
            <li>
              <div
                id="teconsent"
                className="caption1-regular cursor-pointer border-b text-neutral-25 duration-150 hover:border-yellow-500 hover:text-yellow-500"
              />
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
